import React from 'react'
import * as styles from './styles.module.scss'

import Deal from 'components/Deal'
import Inview from 'components/Inview'

const DealCategory = ({ category, isHome }) => {
  return (
    <Inview className={`${styles.dealCategory} ${isHome ? styles.isHome : ''}`}>
      {!isHome && <h2 className='fade-in slower'>{category.title}</h2>}
      <div className={styles.deals}>
        {category.deals.map((item, i) => (
          <Deal key={i} item={item} isHome={isHome} />
        ))}
        <Deal isEmpty={true} isHome={isHome} />
        <Deal isEmpty={true} isHome={isHome} />
        <Deal isEmpty={true} isHome={isHome} />
        <Deal isEmpty={true} isHome={isHome} />
        <Deal isEmpty={true} isHome={isHome} />
        <Deal isEmpty={true} isHome={isHome} />
        <Deal isEmpty={true} isHome={isHome} />
      </div>
    </Inview>
  )
}

export default DealCategory
