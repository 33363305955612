import React from 'react'
import * as styles from './styles.module.scss'
import { Link } from 'gatsby'
import { tr } from 'js/translations.js'

import BgLink from 'components/BgLink'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'

const NewsLink = ({ post, locale, isPinned }) => {
  const { slug, title, postDate, category, imageThumb, excerpt } = post
  const isPinnedClass = isPinned ? styles.isPinned : ''
  return (
    <Inview
      className={`${styles.newsLink} ${isPinnedClass}
    fade-in-up
    `}
    >
      {imageThumb && (
        <div className={styles.image}>
          <Link to={`/news/${slug}/`}>
            <ImageWrap image={imageThumb} alt='' />
          </Link>
        </div>
      )}
      <div className={styles.text}>
        <h4>
          {postDate}
          {category && <span>{category.title}</span>}
        </h4>
        <h2>{title}</h2>
        {excerpt && <p>{excerpt.excerpt}</p>}
        <BgLink
          to={`/news/${slug}/`}
          text={`${tr('READ_MORE', locale)} »`}
          hasDelay={false}
        />
      </div>
    </Inview>
  )
}
// {/* <BgLink to={'/case-studies/' + slug} text={`${tr('READ_MORE', locale)} »`} /> */}

export default NewsLink
