import React from 'react'
import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { Link } from 'gatsby'
import * as styles from './index.module.scss'
import { tr } from 'js/translations.js'

import Banner from 'components/Banner'
import BgLink from 'components/BgLink'
import DealCategory from 'components/DealCategory'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import Layout from 'components/Layout'
import NewsLink from 'components/NewsLink'
import Seo from 'components/Seo'
import TeamMember from 'components/TeamMember'

const Page = ({ data }) => {
  const locale = process.env.GATSBY_LOCALE
  const page = data['page'].nodes[0]
  const posts = data['posts'].nodes
  const postsLocale =
    locale === 'en'
      ? posts.filter((post) => post.publishEn)
      : posts.filter((post) => post.publishZh)
  const postsLocaleFirst2 = postsLocale.slice(0, 2)

  const introText = renderRichText(page.introText)
  const dealsText = renderRichText(page.dealsText)
  const teamText = renderRichText(page.teamText)
  const newsText = renderRichText(page.newsText)

  return (
    <Layout>
      <div>
        <Seo page={page} locale={locale} />
        <Banner
          images={page.slideshow}
          addClass='banner-home'
          locale={locale}
        />
        <div className={styles.trackWrap}>
          <div className={`${styles.trackWrapWrapper} wrapper no-bg pad-sides`}>
            <div className={`inner ${styles.trackWrapInner}`}>
              {page.trackRecordItems.map((item, i) => (
                <TrackItem key={i} index={i} item={item} />
              ))}
            </div>
          </div>
        </div>

        <div className='wrapper pad-sides'>
          <div className='inner'>
            <div className={styles.introText}>
              <Inview>
                <div className={styles.inner}>
                  <div className='fade-in slower stagger-500'>{introText}</div>

                  <div className='fade-in stagger-1500'>
                    <BgLink
                      to='/approach/'
                      text={`${tr('READ_MORE', locale)} »`}
                      isCentered={true}
                    />
                  </div>
                </div>
              </Inview>
            </div>
          </div>
        </div>
        <div className={styles.dealsWrap}>
          <div className={`${styles.dealsWrapWrapper} wrapper pad-sides no-bg`}>
            <Inview className='inner'>
              {dealsText}
              {page.dealCategories.map((category, i) => (
                <DealCategory key={i} category={category} isHome={true} />
              ))}
              <div className={styles.linkWrap}>
                <BgLink
                  to='/deals/'
                  text={`${tr('READ_MORE', locale)} »`}
                  isCentered={true}
                />
              </div>
            </Inview>
          </div>
        </div>
        <div className='wrapper pad-sides'>
          <div className='inner'>
            <div className={styles.teamWrap}>
              <Inview className={styles.text}>
                <div className='fade-in slower stagger-500'>{teamText}</div>
                <div className='fade-in stagger-1500'>
                  <BgLink to='/team/' text={`${tr('READ_MORE', locale)} »`} />
                </div>
              </Inview>
              <div className={styles.people}>
                {page.teamPeople.map((item, i) => (
                  <TeamMember
                    key={i}
                    item={item}
                    hasText={true}
                    isHome={true}
                    locale={locale}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.newsWrap}>
          <div className={`${styles.newsWrapWrapper} wrapper pad-sides`}>
            <div className='inner'>
              <Inview className='fade-in'>{newsText}</Inview>
              <div className={styles.newsWrapInner}>
                <Inview className={`${styles.image} fade-in`}>
                  <Link to='/news/'>
                    <ImageWrap image={page.newsText.references[0]} alt='' />
                  </Link>
                </Inview>
                <div className={styles.text}>
                  {postsLocaleFirst2.map((post, i) => (
                    <NewsLink key={i} post={post} locale={locale} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page

const TrackItem = ({ item, index }) => {
  const stagger = index * 500
  return (
    <Inview className={styles.item}>
      <div className={`fade-in-up stagger-${stagger}`}>
        <p>{item.value}</p>
        <h4>{item.label}</h4>
      </div>
    </Inview>
  )
}

export const pageQuery = graphql`
  query {
    page: allContentfulHomePage {
      nodes {
        slideshow {
          ...GatsbyImage
        }
        introText {
          raw
        }
        trackRecordItems {
          value
          label
        }
        dealsText {
          raw
        }
        dealCategories {
          title
          deals {
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1.2
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
            title
            excerpt
            tags
          }
        }
        teamText {
          raw
        }
        teamPeople {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 0.83333333333
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
          slug
          name
          jobTitle
        }
        newsText {
          raw
          references {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        seo {
          ...SeoFields
        }
      }
    }
    posts: allContentfulNewsPost(sort: { fields: postDate, order: DESC }) {
      nodes {
        publishZh
        publishEn
        slug
        title
        postDate(formatString: "YYYY-M-DD")
      }
    }
  }
`
