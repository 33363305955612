// extracted by mini-css-extract-plugin
export var dealsWrap = "index-module--deals-wrap--Tb19F";
export var dealsWrapWrapper = "index-module--deals-wrap-wrapper--ZDYjJ";
export var image = "index-module--image--mTWLZ";
export var inner = "index-module--inner--lbXNW";
export var introText = "index-module--intro-text--ZyK77";
export var item = "index-module--item--XEGSa";
export var linkWrap = "index-module--link-wrap--3LxAY";
export var newsWrap = "index-module--news-wrap--i5cNQ";
export var newsWrapInner = "index-module--news-wrap-inner--gJATD";
export var newsWrapWrapper = "index-module--news-wrap-wrapper--JI7sW";
export var people = "index-module--people--r3aeL";
export var teamWrap = "index-module--team-wrap--xdi16";
export var text = "index-module--text--kuZYQ";
export var trackWrap = "index-module--track-wrap--kb7jz";
export var trackWrapInner = "index-module--track-wrap-inner--14lsp";
export var trackWrapWrapper = "index-module--track-wrap-wrapper--RgOUC";